import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Text, VerticalStack } from "@shopify/polaris";
import { AddWidgetButton } from "../../AdminSetupRoute/Tabs/OnSiteSetupNew/components/AddWidgetButton";
import { useThemeCustomizationUrl } from "../../AdminSetupRoute/libs/utils";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { parseShopName } from "@smartrr/shared/utils/parseShopName";
import { BundleConfigStoreAccess } from "../store";
import { BundleThemeSelect } from "./BundleThemeSelect";
import { ContactSupportBanner } from "../../AdminSetupRoute/Tabs/OnSiteSetupNew/components/ContactSupportBanner";

export const BundleAddWidgetCard = () => {
  const [isThemeValid, setIsThemeValid] = useState<boolean>(true);
  const [themeId, setThemeId] = useState<string>();

  const themes = BundleConfigStoreAccess.useThemes();
  const isLoading = BundleConfigStoreAccess.useLoading();
  const { initializeThemes, validateTheme } = BundleConfigStoreAccess.useActions();

  const org = useActiveOrganizationSelector();
  const { shopName } = parseShopName(org?.myShopifyDomain);
  const themeCustomizeLink = useThemeCustomizationUrl(shopName, themeId, "/");

  const onSelect = useCallback(async (id: string) => {
    const isValid = await validateTheme(id, "templates/customers/account.json");
    setIsThemeValid(isValid);
    setThemeId(id);
  }, []);

  const disabled = useMemo(
    () => !isThemeValid || !themes.length || !themeId,
    [isThemeValid, themes.length, themeId]
  );

  useEffect(() => {
    void initializeThemes();
  }, []);

  return (
    <Card>
      <VerticalStack gap="5">
        <Text as="h2" variant="headingMd">
          Add bundle widget
        </Text>
        {!isThemeValid && <ContactSupportBanner widgetType="bundle" />}
        <Text as="p">
          Add the Smartrr bundle widget to the homepage of your storefront. You only need to do this once for the
          themes that you want to show bundles on.
        </Text>
        <BundleThemeSelect themes={themes} onSelect={onSelect} isLoading={isLoading} />
        <AddWidgetButton url={themeCustomizeLink} disabled={disabled} />
      </VerticalStack>
    </Card>
  );
};
