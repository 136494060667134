import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import { toViewShortDate } from "@smartrr/shared/utils/renderViewDate";

export const getBundleThemeSelectOptions = (themes: ISmartrrShopTheme[] | undefined) => {
  return (themes ?? []).reduce(
    (acc, theme, index) => {
      if (index === 0) {
        acc.push({ label: "Select a theme to add widget to", value: "" });
      }
      acc.push({
        label: `${theme.name} | Updated: ${toViewShortDate(new Date(theme.updated_at))} ${
          theme.role === "main" ? "(Live theme)" : ""
        }`,
        value: String(theme.id),
      });
      return acc;
    },
    [] as { label: string; value: string }[]
  );
};
