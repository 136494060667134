import { Select, Text } from "@shopify/polaris";
import { getBundleThemeSelectOptions } from "../libs/utils/getBundleThemeSelectOptions";
import { useCallback, useMemo, useState } from "react";
import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";

interface ThemeSelectProps {
  onSelect(themeId: string): void;
  themes: ISmartrrShopTheme[];
  isLoading: boolean;
}

export const BundleThemeSelect = ({ onSelect, themes, isLoading }: ThemeSelectProps) => {
  const [selected, setSelected] = useState<string>("");

  const onChange = useCallback((themeId: string) => {
    onSelect(themeId);
    setSelected(themeId);
  }, []);

  const options = useMemo(() => getBundleThemeSelectOptions(themes), [themes]);

  if (!themes.length) {
    return (
      <Text as="p" color="subdued">
        No themes found
      </Text>
    );
  }

  return <Select label="Theme" value={selected} options={options} onChange={onChange} disabled={isLoading} />;
};
