import { Button, Icon, LegacyCard, LegacyStack, Link, Text, VerticalStack } from "@shopify/polaris";
import { CollectionsMajor, ExternalSmallMinor, PaintBrushMajor } from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import { adminConfigRoutePrefix } from "@smartrr/shared/constants";
import { ISmartrrBundleConfig } from "@smartrr/shared/entities/SellingPlanGroup";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { getPurchasableImage } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import BundleImage from "./BundleNotAvailable.png";
import { Spinner } from "../../components/elements/Spinner";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";

const NotAvaialableContainer = styled.div`
  display: flex;
  img {
    border-radius: 8px;
  }
`;

const NotAvaialableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const IconContainer = styled.div`
  .Polaris-LegacyStack {
    align-items: center;
  }
  .Polaris-Thumbnail {
    height: 2.1rem;
  }
  img {
    border: 1px solid #c9cccf;
    border-radius: 4px;
    height: 30px;
    padding: 1px;
    width: 30px;
  }
  .Polaris-Link {
    display: flex;
  }
`;

export function BundlesContainer({ bundleConfigs }: { bundleConfigs: ISmartrrBundleConfig[] }): JSX.Element {
  const [sellingPlanGroupFromDb, setSellingPlanGroupFromDb] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const organization = useActiveOrganizationSelector();
  const shopUrl = organization?.myShopifyDomain;
  const { bundleExtension: bundleExtensionFlag } = useSmartrrFlags();

  useEffect(() => {
    getSellingPlanGroupFromDb();
  }, []);

  const getSellingPlanGroupFromDb = async () => {
    const res = await typedFrontendVendorApi.getReq(`/selling-plan-group`);
    if (res.type === "success") {
      setSellingPlanGroupFromDb(res.body);
    }
    setLoading(false);
  };

  if (loading) {
    return <Spinner />;
  }

  if (isEmpty(sellingPlanGroupFromDb)) {
    return (
      <LegacyStack vertical>
        <Text variant="headingMd" as="h2">
          <LegacyStack distribution="equalSpacing">
            <Text variant="headingLg" as="p" fontWeight="semibold">
              Bundles
            </Text>
            <Button primary disabled>
              Create bundle
            </Button>
          </LegacyStack>
        </Text>
        <LegacyCard>
          <NotAvaialableContainer>
            <img src={BundleImage} width="232" height="226" alt="Not available" />
            <NotAvaialableTextContainer>
              <Text variant="headingMd" as="h2">
                <LegacyStack>
                  <span>You must create a subscription program</span>
                </LegacyStack>
              </Text>
              <p>
                Subscription programs are required to access Bundles. Please create a subscription program first.
              </p>
              <div>
                <Button primary url="/admin/configure/plans">
                  Create Subscription Program
                </Button>
              </div>
            </NotAvaialableTextContainer>
          </NotAvaialableContainer>
        </LegacyCard>
      </LegacyStack>
    );
  }

  const CTAText = "Create bundles to offer custom 'build-a-box' type subscriptions to your customers.";
  return (
    <React.Fragment>
      {bundleConfigs.length === 0 ? (
        <VerticalStack gap="5">
          <Text variant="headingLg" as="p" fontWeight="semibold">
            Bundles
          </Text>
          <LegacyCard sectioned>
            <Box
              vertical
              gap={1}
              py={6}
              px={1}
              alignItems="center"
              justifyContent="center"
              style={{ textAlign: "center" }}
            >
              <Text variant="headingLg" as="p">
                You haven&#39;t created any bundles yet.
              </Text>
              <Text variant="bodyMd" as="span" color="subdued">
                {CTAText}
              </Text>
              <Button primary onClick={() => navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles/create`)}>
                Create bundle
              </Button>
            </Box>
          </LegacyCard>
        </VerticalStack>
      ) : (
        <LegacyStack vertical spacing="loose">
          <Text variant="headingMd" as="h2">
            <LegacyStack distribution="equalSpacing">
              <Text variant="headingLg" as="p">
                <Text variant="headingLg" as="p" fontWeight="semibold">
                  Bundles
                </Text>
              </Text>
              <Button primary onClick={() => navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles/create`)}>
                Create bundle
              </Button>
            </LegacyStack>
          </Text>
          {bundleConfigs.map(bundle => {
            const product = purchasables.find(p => p.shopifyId === bundle.bundleProduct);
            const productNumId = product && shopifyGidToNumber(product?.shopifyId!);
            const imageUrl = getPurchasableImage(product!);

            return (
              <LegacyCard key={bundle.id} sectioned>
                <LegacyStack vertical spacing="loose">
                  <Text variant="headingMd" as="h2">
                    <LegacyStack distribution="equalSpacing">
                      <Text variant="bodyLg" as="span" fontWeight="semibold">
                        {bundle.name}
                      </Text>
                      <Button
                        plain
                        onClick={() => navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles/${bundle.id}`)}
                      >
                        Manage
                      </Button>
                    </LegacyStack>
                  </Text>
                  <LegacyStack vertical spacing="tight">
                    {product ? (
                      <React.Fragment>
                        <span>{bundle.packs.map(pack => pack.name).join(", ")}</span>
                        <IconContainer>
                          <LegacyStack spacing="tight">
                            <Icon source={CollectionsMajor} color="base" />
                            {!!imageUrl && <img src={imageUrl} alt={product?.purchasableName} />}
                            <Link external url={`https://${shopUrl}/admin/products/${productNumId}`}>
                              {" "}
                              {product?.purchasableName}
                              <Icon color="interactive" source={ExternalSmallMinor} />
                            </Link>
                          </LegacyStack>
                        </IconContainer>
                      </React.Fragment>
                    ) : (
                      <span style={{ color: "red" }}>Deleted Bundle Product</span>
                    )}
                  </LegacyStack>
                </LegacyStack>
              </LegacyCard>
            );
          })}
          {bundleExtensionFlag ? (
            <React.Fragment />
          ) : (
            <LegacyCard>
              <NotAvaialableContainer>
                <img src={BundleImage} width="232" height="226" alt="Not available" />
                <NotAvaialableTextContainer>
                  <Text variant="headingMd" as="h2">
                    <LegacyStack>
                      <Icon source={PaintBrushMajor} color="base" />
                      <span>Customize your bundle builder</span>
                    </LegacyStack>
                  </Text>
                  <p>
                    Your store&apos;s on-site bundle builder can be styled with custom colors and typography by
                    editing the CSS on the snippet <strong>smartrr-bundle-css.liquid</strong> in your theme&apos;s
                    liquid code.
                  </p>
                  <div>
                    <Button external url={`https://${shopUrl}/admin/themes`}>
                      Edit theme
                    </Button>
                  </div>
                </NotAvaialableTextContainer>
              </NotAvaialableContainer>
            </LegacyCard>
          )}
        </LegacyStack>
      )}
    </React.Fragment>
  );
}
