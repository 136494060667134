import { immer } from "zustand/middleware/immer";
import { create } from "zustand";
import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import {
  fetchShopifyThemes,
  ThemeValidationAssetFiles,
  validateShopifyTheme,
} from "../../AdminSetupRoute/libs/utils/themes";

interface BundleConfigStore {
  themes: ISmartrrShopTheme[];
  activeThemeId: string;
  isThemeValid: boolean;
  isLoading: boolean;

  error: {
    showError: boolean;
    message: string;
  };

  actions: {
    initializeThemes(): void;
    validateTheme(themeId: string | number, file: ThemeValidationAssetFiles): Promise<boolean>;
  };

  internal: {
    whileLoading(loadFn: () => Promise<void>): Promise<void>;
    fetchThemes(): void;
    clearError(): void;
    logError(message: string): void;
  };
}

const useBundleConfigStore = create<BundleConfigStore>()(
  immer((set, get) => ({
    themes: [],
    activeThemeId: "",
    isThemeValid: false,
    isLoading: false,

    error: {
      showError: false,
      message: "",
    },

    actions: {
      async initializeThemes() {
        const { fetchThemes, whileLoading } = get().internal;

        await whileLoading(async () => {
          fetchThemes();
        });
      },

      async validateTheme(id: string, file) {
        if (!id) {
          return true;
        }
        const { internal } = get();
        const themeId = +id;
        let isValid = true;
        try {
          await internal.whileLoading(async () => {
            const res = await validateShopifyTheme(themeId, file);
            isValid = res;
          });
        } catch {
          internal.logError("Error validating theme, please try again.");
        }
        return isValid;
      },
    },

    internal: {
      async whileLoading(loadfn: () => Promise<void>): Promise<void> {
        set({
          isLoading: true,
        });
        await loadfn();
        set({ isLoading: false });
      },

      async fetchThemes() {
        const { themes: storedThemes, internal } = get();
        try {
          if (!storedThemes.length) {
            const themes = await fetchShopifyThemes();
            set({
              themes,
            });
          }
        } catch (error) {
          internal.logError(error.message);
        }
      },

      clearError() {
        set({
          error: {
            message: "",
            showError: false,
          },
        });
      },

      logError(message) {
        set(draft => {
          draft.error = {
            showError: true,
            message: draft.error.message ? `${draft.error.message}, ${message}` : message,
          };
        });
      },
    },
  }))
);

const initialState = useBundleConfigStore.getState();

export const BundleConfigStoreAccess = {
  useLoading: () => useBundleConfigStore(state => state.isLoading),
  useActions: () => useBundleConfigStore(state => state.actions),
  useThemes: () => useBundleConfigStore(state => state.themes),

  testing: {
    state: useBundleConfigStore.getState,
    actions: useBundleConfigStore.getState().actions,
    reset(state?: Partial<BundleConfigStore>) {
      useBundleConfigStore.setState({ ...initialState, ...state });
    },
  },
};
